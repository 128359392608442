<template>
  <div class="Details_I">
    <div class="GuidesNav">
      <div @click="fanhui" style="cursor: pointer;"><i class="el-icon-arrow-left"></i> <span>返回</span></div>
      <div class="GuidesNavLi">
        <div class="GuidesNavLeft">
          <el-image :src="book.full_image" :fit="fit"></el-image>
        </div>
        <div class="GuidesNavRight">
          <div class="GuidesNavName">{{book.name}}</div>
          <div class="GuidesNavAuthor">作者：{{book.author}} <span>|</span> 更新时间：{{book.createtime}}</div>
          <div class="GuidesNavBrief">{{book.intro}}</div>
          <div class="GuidesNavMaterial">
            <span v-for="(obj ,s) in book.tag" :key="s">{{obj}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="GuidesNav">
      <div class="GuidesNavLI" v-for="item in chapterList" :key="item.id">
        <div class="GuidesNavLITop">
          <div class="LITop">
            <div style="width: 40%;" @click="gaibian(item.id)">{{item.name}} （共{{item.content_list.length}}节）</div>
            <div class="LITopGG" v-if="Zname == item.id">
              <div><el-input v-model="NaMe"></el-input></div>
              <div>
                <span style="color: #0081ff;" @click="chapterEdit(item)">确认</span>
                <span @click="Zname = 9999988">取消</span>
                <span style="color: red;" @click="chapterDelt(item.id)">删除</span>
              </div>
            </div>
          </div>
          <div style="color: #0081ff;cursor: pointer;margin-right:30px" @click="chuangjian(item)">创建指南</div>
        </div>
        <div class="GuidesNavLIBtn">
          <div class="GuidesNavLIBtnLi" v-for="obj in item.content_list " :key="obj">
            <div class="GuidesNavLIBtnLiname ellipsis" :title="obj.title" @click="titleClick(obj)">{{obj.title}}</div>
            <div class="GuidesNavLIBtnLiright">
              <div style="color: #0081ff;" v-if="obj.is_new == 1">[新]</div>
              <div style="color: red;cursor: pointer;" @click="HandleEdit(obj.id)">[编辑]</div>
              <div style="color: red;cursor: pointer;" @click="HandleDel(obj.id)">[删除]</div>
            </div>
          </div>
        </div>
      </div>
      <div class="NavLiQuery" style="width:80px;margin-top:30px" @click="chapterAdd()"><i class="el-icon-plus" ></i>创建章节</div>
    </div>

    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew aaa">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm">
          <el-form-item class="EditNewFormItemTube">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>指南合集（默认）</div>
            <div class="EditNewFormItemIpt">
              <el-input type="tel" disabled v-model="book_name"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>章节（默认）</div>
            <div class="EditNewFormItemIpt">
              <el-input type="tel" disabled v-model="chapter_name"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>指南名称（题目）</div>
            <div class="EditNewFormItemIpt">
              <el-input type="tel" v-model="Row.title" placeholder="请填写题目"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>创作者</div>
            <div class="EditNewFormItemIpt">
              <el-input type="tel" v-model="Row.author" placeholder="请填写作者"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>排序权重</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.weigh" placeholder="数值越大，排序越靠前"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemIpt">
              <div id="demo1"></div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>

        <div class="EditNewForm" style="padding: 0 24px;" v-if="isBook == 2">
          <div>
            <div class="Rig_A_E1">{{form.title}}</div>
            <div class="Rig_A_E2">创作者：{{form.author}} &nbsp;&nbsp;&nbsp;  发布日期：{{form.createtime}} &nbsp;&nbsp;&nbsp;   浏览：{{form.views}}</div>
            <div class="Rig_A_E3" v-html="form.content"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="show" v-if="show1">
      <div class="rightPanel-background"/>
      <div class="EditNew bbbb">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <div class="EditNewForm" style="padding: 0 24px;">
          <div>
            <div class="Rig_A_E1">{{contentInfo.title}}</div>
            <div class="Rig_A_E2">创作者：{{contentInfo.author}} &nbsp;&nbsp;&nbsp;  发布日期：{{contentInfo.createtime}} &nbsp;&nbsp;&nbsp;   浏览：{{contentInfo.views}}</div>
            <div class="Rig_A_E3 w-e-text" v-html="contentInfo.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import wangEditor from 'wangeditor'
export default {
  props:['DetailsId'],
  data(){
    return{
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      chapterList:[],
      contentInfo:[],
      book:{},
      isBook:'',
      Zname:0,
      NaMe:'',  //章节名称
      addPanel:false,
      editPanel:false,
      show1:false,
      editPanel:'',
      titleMath:'',
      wangE:'',
      editor:'',
      editorData:'',
      bookId:'',
      itemId:'',
      dataId:'',
      chapter_name:'',
      book_name:'',
      Row:{
        book_id:'',
        chapter_id:'',
        title:'',
        author:'',
        weigh:'',
        content:'',
        isShow:false
      }
    }
  },
  mounted(){
    var that =  this
    that.loadTable()
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
  },

  methods:{
     // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'GET',
        url: '/admin/workbench/guide/chapterList',
        params: {
          id : that.DetailsId
        },
      }).then(function (res) {
        that.book = res.data.data.book
        that.chapterList = res.data.data.chapterList
        that.$forceUpdate()
      })
    },
    //显示章节输入框
    gaibian(e){
      var that = this
      var att = that.chapterList
      that.Zname = e
      for (let i = 0; i < att.length; i++) {
        if (att[i].id == e) {
          console.log(att[i].name)
          that.NaMe = att[i].name
        }
      }
    },
    //创建打开
    chuangjian(data){
      var that = this
      that.addPanel = true
      that.isBook = 1
      that.titleMath = '创建指南内容'
      that.editPanel = 0
      that.bookId = that.book.id
      that.itemId = data.id
      that.Row.title  =  ''
      that.book_name = that.book.name
      that.Row.author = ''
      that.Row.weigh = ''
      that.chapter_name = data.name
      that.book_name = that.book.name
      that.editorData = ''
      if (that.wangE == 20) {
        that.editor.destroy();
        that.editor = null
      } else {
        that.wangE = 0
      }
      console.log(that.wangE)
      that.init()
    },
    //确定创建
    BtnAdd(){
      var that = this
      that.Row.content = that.editorData
      axios({
        method: 'post',
        url: '/admin/workbench/guide/addContent',
        data: {
          row: {
            book_id: that.bookId,
            chapter_id: that.itemId,
            title: that.Row.title,
            weigh: that.Row.weigh,
            author: that.Row.author,
            content: that.editorData
          }
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.addPanel = false
          that.loadTable()
        }
      })
    },
    //删除
    HandleDel(data) {
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
            method: 'delete',
            url: '/admin/workbench/guide/delContent',
            data: {
              id: data
            }
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.loadTable();
            }
        })
      })
    },
    //编辑详情
    HandleEdit(data){
      var that = this
      that.editPanel = true
      that.titleMath = '编辑指南内容'
      that.editPanel = 1
      that.isBook = 1
      that.dataId = data
      axios({
        method: 'GET',
        url: '/admin/workbench/guide/contentInfo',
        params: {
          id: data
        }
      }).then(function (res) {
        that.Row = res.data.data.row
        that.editorData = res.data.data.row.content
        that.book_name =  res.data.data.row.book ? res.data.data.row.book.name : '' ,
        that.chapter_name =  res.data.data.row.chapter ? res.data.data.row.chapter.name : ''

        if (that.wangE == 20) {
          that.editor.destroy();
          that.editor = null
        } else {
          that.wangE = 0
        }
        console.log(that.wangE)
        that.init()
      })
    },
    //编辑保存
    BtnEdit() {
      var that = this
      axios({
        method: 'put',
        url: '/admin/workbench/guide/editContent',
        data: {
          id: that.dataId,
          row: {
            book_id: that.Row.book_id,
            chapter_id: that.Row.chapter_id,
            title: that.Row.title,
            weigh: that.Row.weigh,
            author: that.Row.author,
            content: that.editorData
          }
        },
      }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editPanel = false
            that.loadTable();
          }
      })
    },
    //富文本
    init() {
      var that = this

      setTimeout(function() {
        var Eat = that.Eattr
        const editor = new wangEditor(`#demo1`)
        Eat = editor

        editor.config.uploadImgHeaders = {
            token:that.heads.token
        }
        editor.config.onchange = (newHtml) => {
          that.editorData = newHtml
        }
        editor.config.showLinkImg = false  //关闭网络路径图片方式
        editor.config.uploadImgServer = 'https://rest-api.duxin365.com/admin/ajax/editorUpload'  // 上传图片到服务器
        editor.config.uploadFileName = 'file'
        editor.config.uploadImgTimeout = 50000;
        editor.config.uploadImgHooks = {
          // 图片上传并返回结果，但图片插入错误时触发
          fail: function (xhr, editor, result) {
            console.log(result)
          },
          success: function (xhr, editor, result) {
            // 图片上传并返回结果，图片插入成功之后触发
            console.log(result, 'success')
          }
        }
        editor.create()
        // 创建编辑器
        editor.txt.html(that.editorData) // 重新设置编辑器内容
        that.editor = editor
        console.log(that.editor)
        that.wangE = 20
      },400)
    },
    //关闭
    Close(){
      var that = this
      that.editPanel = false
      that.addPanel = false
      that.editorData = ''
      that.show1 = false;

      if(that.wangE == 20) {
        that.editor.destroy();
      }
    },
    //创建章节
    chapterAdd(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/guide/addChapter',
        data: {
          row: {
            book_id:that.DetailsId, //指南ID
            name: '自定义章节', //章节ID
          }
        },
      }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable()
          }
      })
    },
    //编辑章节
    chapterEdit(data){
      var that = this
      console.log(data);
      axios({
        method: 'PUT',
        url: '/admin/workbench/guide/editChapter',
        data: {
          id: data.id,
          row: {
            book_id: data.book_id, //指南ID
            name: that.NaMe, //章节ID
          }
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.warning(res.data.msg);
          that.Zname = 994564
          that.loadTable()
        }
      })
    },
    //删除章节
    chapterDelt(data){
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
            method: 'delete',
            url: '/admin/workbench/guide/delChapter',
            data: {
              id: data
            }
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.loadTable();
            }
        })
      })
    },
    //返回
    fanhui(){
      this.$emit("childFn",this.isShow)
    },
    titleClick(data){
      var that = this
      that.show1 = true
      that.titleMath = '阅读指南内容'
      axios({
        method: 'GET',
        url: '/admin/workbench/guide/contentInfo',
        params: {
          id: data.id,
        },
      }).then(function (res) {
        that.contentInfo = res.data.data.row
      })
    }
  }
}
</script>

<style scoped>
.GuidesNav{
  padding: 24px;
  margin: 15px 24px;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
}
.GuidesNavLi{
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  padding-bottom: 35px;
}
.GuidesNavLeft{
  width: 207px;
  height: 260px;
  margin-right: 2%;
  border-radius: 4px;
  overflow: hidden;
}
.el-image{
  width: 100%;
  height: 100%;
}
.GuidesNavRight{
  flex: 1;
  position: relative;
  margin-right: 25px;
}
.GuidesNavName{
  font-size: 15px;
  font-weight: 600;
  font-family: "PingFang SC";
  cursor: pointer;
  margin-top: 8px;
}
.GuidesNavAuthor{
  font-size: 13px;
  color: #8C8C8C;
  display: inline-block;
  padding: 7px 0;
}
.GuidesNavAuthor span{
  display: inline-block;
  padding: 0 5px;
}
.GuidesNavBrief{
  height: 40px;
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:2;
  overflow: hidden;
  text-overflow:ellipsis;
  cursor: pointer;
}
.GuidesNavMaterial span{
  display: inline-block;
  width: 58px;
  height: 24px;
  text-align: center;
  font-size: 12px;
  color: #1890ff;
  border: 1px solid #1890ff;
  margin-top: 16px;
  line-height: 24px;
  border-radius: 3px;
  margin-right: 15px;
}
.GuidesNavLITop{
  background-color: #FAFAFA;
  border-radius: 8px;
  height: 50px;
  line-height: 50px;
  text-indent: 2em;
  font-size: 14px;
  margin: 8px 0;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
}
.LITop{
  display:flex;
  width: 80%;
}
.LITopGG{
  width: 80%;
  display: flex;
}
.LITopGG div{
  margin-right: 35px;
}
.LITopGG span{
  display: inline-block;
}
.GuidesNavLIBtn{
  display: flex;
  flex-wrap: wrap;
}
.GuidesNavLIBtnLi{
  width: 33.3%;
  display: flex;
  height: 50px;
  line-height: 50px;
  margin-bottom: 15px;
  justify-content: space-between;
}
.GuidesNavLIBtnLiright{
  display: flex;
  margin-right: 12%;
}
.GuidesNavLIBtnLiright div{
  margin: 0 10px;
}
.GuidesNavLIBtnLiname{
  margin-left: 10px;
  cursor: pointer;
  width: 55%;
}
.ellipsis{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.aaa{
  width:760px
}
.bbbb{
  width:1000px
}
.Details_I .Rig_A_E1 {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
  margin-top: 30px;
}
.Details_I .Rig_A_E2 {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  margin: 16px 0 25px;
}
.Details_I .Rig_A_E3 {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  line-height: 26px;
}
</style>
